import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

const SideMenu = ({ phone, mail }) => {
  const [showMenu, setShowMenu] = useState(0)

  useEffect(() => {
    document.addEventListener('scroll', handleScroll)
    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleScroll = () => {
    const wY = window.scrollY
    const wH = window.innerHeight

    if (wY > wH * 0.75) {
      setShowMenu(1)
    } else {
      setShowMenu(0)
    }
  }

  //if (isMD) return <div />

  return (
    <div className={`dhsv_side_menu ${showMenu ? 'active' : ''}`}>
      <div className={`open-menu`}>
        <i className="material-icons">phone</i>
      </div>
      <div className={`side_menu__items`}>
        <span className={`close`}>
          <i className="material-icons">close</i>
        </span>
        {phone && (
          <a className="noicon" href={`tel:${phone}`}>
            <div>
              <i className="material-icons">phone</i>
            </div>
            <div>
              <span>{phone}</span>
            </div>
          </a>
        )}
        {mail && (
          <a className="noicon" href={`mailto:${mail}`}>
            <div>
              <i className="material-icons">mail</i>
            </div>
            <div>
              <span>{mail}</span>
            </div>
          </a>
        )}
        {/*{link &&
          link.url && (
            <a href={`${link.url}`}>
              <div>
                <i className="material-icons">developer_board</i>
              </div>
              <div>
                <span>{link.title}</span>
              </div>
            </a>
          )}*/}
      </div>
    </div>
  )
}

SideMenu.propTypes = {
  isMD: PropTypes.bool,
  phone: PropTypes.string,
  mail: PropTypes.string,
  link: PropTypes.object,
}

export default SideMenu
