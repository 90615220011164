import React, { useState } from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

const NewSideMenu = props => {
  const { phone, mail, link } = props
  const [showMenu, setShowMenu] = useState(false)

  const data = [
    {
      title: phone,
      url: phone,
      icon: 'call',
    },
    {
      title: mail,
      url: mail,
      icon: 'email',
    },
    {
      ...link,
      icon: 'developer_board',
    },
  ]

  return (
    <div className={`dhsv_new_side-menu ${showMenu ? 'open' : ''}`}>
      <div className="inner">
        <button
          className={'material-icons'}
          onClick={() => setShowMenu(!showMenu)}
        >
          {showMenu ? 'close' : 'call'}
        </button>
        <div className="content">
          <ul>
            {data.map((item, key) => {
              return (
                <li key={key}>
                  <a href={item.url}>
                    <span className={'material-icons'}>{item.icon}</span>
                    <span className="label">{item.title}</span>
                  </a>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </div>
  )
}

NewSideMenu.propTypes = {
  isMD: PropTypes.bool,
  phone: PropTypes.string,
  mail: PropTypes.string,
  link: PropTypes.object,
}

export default NewSideMenu
